@use 'sass:map';
@use '@angular/material' as mat;

@mixin custom-links-color($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);

  .custom-link-header {
    background-color: mat.m2-get-color-from-palette($accent, 100);
  }
}

@mixin custom-links-color-dark($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, 'primary');
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');

  .custom-link-header {
    background-color: mat.m2-get-color-from-palette($primary, 'darker');
  }
}

@mixin custom-links-theme($theme, $dark-theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.m2-get-color-config($theme);
  $typography: mat.m2-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include custom-links-color($color);
    .dark-mode {
      @include custom-links-color-dark($dark-theme);
    }
  }
  @if $typography != null {
    @include custom-links-typography($typography);
  }
}
