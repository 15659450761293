/* Colors Schemes */
@use '@angular/material' as mat;

$md-darkbluegray: (
  50: #e6e6e8,
  100: #c0c1c5,
  200: #96989f,
  300: #6b6f79,
  400: #4c505c,
  500: #2c313f,
  600: #272c39,
  700: #212531,
  800: #1b1f29,
  900: #10131b,
  A100: #5f87ff,
  A200: #2c61ff,
  A400: #003ef8,
  A700: #0038df,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-desaturatedbluegray: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78919c,
  500: #607d8b,
  600: #587583,
  700: #4e6a78,
  800: #44606e,
  900: #334d5b,
  A100: #abe1ff,
  A200: #78ceff,
  A400: #45bcff,
  A700: #2bb3ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-greentea: (
  50: #f4feed,
  100: #e3fcd3,
  200: #d0fab6,
  300: #bdf898,
  400: #aef782,
  500: #a0f56c,
  600: #98f464,
  700: #8ef259,
  800: #84f04f,
  900: #73ee3d,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e1ffd4,
  A700: #cfffbb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-junglegreen: (
  50: #e1ecec,
  100: #b5cfcf,
  200: #84b0af,
  300: #52908f,
  400: #2d7877,
  500: #08605f,
  600: #075857,
  700: #064e4d,
  800: #044443,
  900: #023332,
  A100: #6bfffb,
  A200: #38fff9,
  A400: #05fff8,
  A700: #00ebe4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-darkerlight: (
  50: #e3e4e9,
  100: #b9bdc7,
  200: #8a91a2,
  300: #5b647d,
  400: #384361,
  500: #152245,
  600: #121e3e,
  700: #0f1936,
  800: #0c142e,
  900: #060c1f,
  A100: #5d78ff,
  A200: #2a4eff,
  A400: #0029f6,
  A700: #0025dc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$generic-accent-color: #607d8b;

/* Palettes */

$globalsearch-web-dark-primary: mat.m2-define-palette(
  $md-darkbluegray,
  500,
  100,
  700,
  50
);
$globalsearch-web-dark-accent: mat.m2-define-palette($md-desaturatedbluegray);
$globalsearch-web-primary: mat.m2-define-palette($md-darkerlight);
$globalsearch-web-accent: mat.m2-define-palette($md-desaturatedbluegray);
$globalsearch-web-warn: mat.m2-define-palette(mat.$m2-red-palette);
