/*
  This file includes style changes that temporarily fix the ngx datepicker. This along with the import in the styles.scss should be removed when the issue is resolved.

  Workaround comment - https://github.com/h2qutc/angular-material-components/issues/357#issuecomment-1755492289
*/
.mat-datepicker-toggle {
  pointer-events: auto;
  color: var(--mat-datepicker-toggle-icon-color);
}

.mat-datepicker-toggle-active {
  color: var(--mat-datepicker-toggle-active-state-icon-color);
}

.cdk-high-contrast-active .mat-datepicker-toggle-default-icon {
  color: canvastext;
}

.mat-datepicker-content {
  box-shadow: rgb(0 0 0 / 20%) 0 2px 4px -1px, rgb(0 0 0 / 14%) 0 4px 5px 0,
    rgb(0 0 0 / 12%) 0 1px 10px 0;
  display: block;
  border-radius: 4px;
  background-color: var(--mat-datepicker-calendar-container-background-color);
  color: var(--mat-datepicker-calendar-container-text-color);
}

.mat-datepicker-content .mat-calendar {
  width: 296px;
  height: 354px;
}

.mat-datepicker-content
  .mat-datepicker-content-container-with-custom-header
  .mat-calendar {
  height: auto;
}

.mat-datepicker-content .mat-datepicker-close-button {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
}

.ng-animating .mat-datepicker-content .mat-datepicker-close-button {
  display: none;
}

.mat-datepicker-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mat-datepicker-content-touch {
  box-shadow: rgb(0 0 0 / 20%) 0 11px 15px -7px,
    rgb(0 0 0 / 14%) 0 24px 38px 3px, rgb(0 0 0 / 12%) 0 9px 46px 8px;
  display: block;
  max-height: 80vh;
  position: relative;
  overflow: visible;
}

.mat-datepicker-content-touch .mat-datepicker-content-container {
  min-height: 312px;
  max-height: 788px;
  min-width: 250px;
  max-width: 750px;
}

.mat-datepicker-content-touch .mat-calendar {
  width: 100%;
  height: auto;
}
@media (orientation: landscape) {
  .mat-datepicker-content-touch .mat-datepicker-content-container {
    width: 64vh;
    height: 80vh;
  }
}
@media (orientation: portrait) {
  .mat-datepicker-content-touch .mat-datepicker-content-container {
    width: 80vw;
    height: 100vw;
  }

  .mat-datepicker-content-touch .mat-datepicker-content-container-with-actions {
    height: 115vw;
  }
}

.mat-calendar {
  display: block;
  font-family: var(--mat-datepicker-calendar-text-font);
  font-size: var(--mat-datepicker-calendar-text-size);
}

.mat-calendar-header {
  padding: 8px 8px 0;
}

.mat-calendar-content {
  padding: 0 8px 8px;
  outline: none;
}

.mat-calendar-controls {
  display: flex;
  align-items: center;
  margin: 5% calc(4.71429% - 16px);
}

.mat-calendar-spacer {
  flex: 1 1 auto;
}

.mat-calendar-period-button {
  min-width: 0;
  margin: 0 8px;
  font-size: var(--mat-datepicker-calendar-period-button-text-size);
  font-weight: var(--mat-datepicker-calendar-period-button-text-weight);
}

.mat-calendar-arrow {
  display: inline-block;
  width: 10px;
  height: 5px;
  margin: 0 0 0 5px;
  vertical-align: middle;
  fill: var(--mat-datepicker-calendar-period-button-icon-color);
}

.mat-calendar-arrow.mat-calendar-invert {
  transform: rotate(180deg);
}

[dir='rtl'] .mat-calendar-arrow {
  margin: 0 5px 0 0;
}

.cdk-high-contrast-active .mat-calendar-arrow {
  fill: canvastext;
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
  position: relative;
}

.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-content .mat-calendar-next-button {
  color: var(--mat-datepicker-calendar-navigation-button-icon-color);
}

.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
  inset: 0;
  position: absolute;
  content: '';
  margin: 15.5px;
  border-width: 2px 0 0;
  border-style: solid;
  border-color: currentColor;
  border-image: initial;
}

[dir='rtl'] .mat-calendar-previous-button,
[dir='rtl'] .mat-calendar-next-button {
  transform: rotate(180deg);
}

.mat-calendar-previous-button::after {
  border-left-width: 2px;
  transform: translateX(2px) rotate(-45deg);
}

.mat-calendar-next-button::after {
  border-right-width: 2px;
  transform: translateX(-2px) rotate(45deg);
}

.mat-calendar-table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.mat-calendar-table-header th {
  text-align: center;
  padding: 0 0 8px;
  color: var(--mat-datepicker-calendar-header-text-color);
  font-size: var(--mat-datepicker-calendar-header-text-size);
  font-weight: var(--mat-datepicker-calendar-header-text-weight);
}

.mat-calendar-table-header-divider {
  position: relative;
  height: 1px;
}

.mat-calendar-table-header-divider::after {
  content: '';
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
  height: 1px;
  background: var(--mat-datepicker-calendar-header-divider-color);
}

.mat-calendar-body-cell-content::before {
  margin: calc(calc(var(--mat-focus-indicator-border-width, 3px) + 3px) * -1);
}

.mat-calendar-body-cell:focus .mat-focus-indicator::before {
  content: '';
}

.mat-calendar-hidden-label {
  display: none;
}

.mat-calendar-body {
  min-width: 224px;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
  border-color: var(--mat-datepicker-calendar-date-today-outline-color);
}

.mat-calendar-body-label {
  height: 0;
  line-height: 0;
  text-align: left;
  padding-left: 4.71429%;
  padding-right: 4.71429%;
  font-size: var(--mat-datepicker-calendar-body-label-text-size);
  font-weight: var(--mat-datepicker-calendar-body-label-text-weight);
  color: var(--mat-datepicker-calendar-body-label-text-color);
}

.mat-calendar-body-hidden-label {
  display: none;
}

.mat-calendar-body-cell-container {
  position: relative;
  height: 0;
  line-height: 0;
}

.mat-calendar-body-cell {
  user-select: none;
  cursor: pointer;
  border: none;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  text-align: center;
  outline: none;
  font-family: inherit;
  margin: 0;
}

.mat-calendar-body-cell::before,
.mat-calendar-body-cell::after,
.mat-calendar-body-cell-preview {
  content: '';
  position: absolute;
  top: 5%;
  left: 0;
  z-index: 0;
  box-sizing: border-box;
  display: block;
  height: 90%;
  width: 100%;
}

.mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
.mat-calendar-body-range-start::after,
.mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before,
.mat-calendar-body-comparison-start::after,
.mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
  left: 5%;
  width: 95%;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}

[dir='rtl']
  .mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
[dir='rtl'] .mat-calendar-body-range-start::after,
[dir='rtl']
  .mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before,
[dir='rtl'] .mat-calendar-body-comparison-start::after,
[dir='rtl'] .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
  left: 0;
  border-radius: 0 999px 999px 0;
}

.mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range)::before,
.mat-calendar-body-range-end::after,
.mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
.mat-calendar-body-comparison-end::after,
.mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
  width: 95%;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

[dir='rtl']
  .mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range)::before,
[dir='rtl'] .mat-calendar-body-range-end::after,
[dir='rtl']
  .mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
[dir='rtl'] .mat-calendar-body-comparison-end::after,
[dir='rtl'] .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
  left: 5%;
  border-radius: 999px 0 0 999px;
}

[dir='rtl']
  .mat-calendar-body-comparison-bridge-start.mat-calendar-body-range-end::after,
[dir='rtl']
  .mat-calendar-body-comparison-bridge-end.mat-calendar-body-range-start::after {
  width: 95%;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

.mat-calendar-body-comparison-start.mat-calendar-body-range-end::after,
[dir='rtl']
  .mat-calendar-body-comparison-start.mat-calendar-body-range-end::after,
.mat-calendar-body-comparison-end.mat-calendar-body-range-start::after,
[dir='rtl']
  .mat-calendar-body-comparison-end.mat-calendar-body-range-start::after {
  width: 90%;
}

.mat-calendar-body-in-preview {
  color: var(--mat-datepicker-calendar-date-preview-state-outline-color);
}

.mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
  border-top: 1px dashed;
  border-bottom: 1px dashed;
}

.mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
  border-left: 1px dashed;
}

[dir='rtl'] .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
  border-left: 0;
  border-right: 1px dashed;
}

.mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
  border-right: 1px dashed;
}

[dir='rtl'] .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
  border-right: 0;
  border-left: 1px dashed;
}

.mat-calendar-body-disabled {
  cursor: default;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
  color: var(--mat-datepicker-calendar-date-disabled-state-text-color);
}

.mat-calendar-body-disabled
  > .mat-calendar-body-today:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
  border-color: var(
    --mat-datepicker-calendar-date-today-disabled-state-outline-color
  );
}

.cdk-high-contrast-active .mat-calendar-body-disabled {
  opacity: 0.5;
}

.mat-calendar-body-cell-content {
  top: 5%;
  left: 5%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  line-height: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: 999px;
  color: var(--mat-datepicker-calendar-date-text-color);
  border-color: var(--mat-datepicker-calendar-date-outline-color);
}

.mat-calendar-body-cell-content.mat-focus-indicator {
  position: absolute;
}

.cdk-high-contrast-active .mat-calendar-body-cell-content {
  border: none;
}

.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
  background-color: var(
    --mat-datepicker-calendar-date-focus-state-background-color
  );
}
@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
    background-color: var(
      --mat-datepicker-calendar-date-hover-state-background-color
    );
  }
}

.mat-calendar-body-selected {
  background-color: var(
    --mat-datepicker-calendar-date-selected-state-background-color
  );
  color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: var(
    --mat-datepicker-calendar-date-selected-disabled-state-background-color
  );
}

.mat-calendar-body-selected.mat-calendar-body-today {
  box-shadow: inset 0 0 0 1px
    var(--mat-datepicker-calendar-date-today-selected-state-outline-color);
}

.mat-calendar-body-in-range::before {
  background: var(
    --mat-datepicker-calendar-date-in-range-state-background-color
  );
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: var(
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color
  );
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: var(
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color
  );
}

.mat-calendar-body-comparison-bridge-start::before,
[dir='rtl'] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(
    to right,
    var(--mat-datepicker-calendar-date-in-range-state-background-color) 50%,
    var(
        --mat-datepicker-calendar-date-in-comparison-range-state-background-color
      )
      50%
  );
}

.mat-calendar-body-comparison-bridge-end::before,
[dir='rtl'] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(
    to left,
    var(--mat-datepicker-calendar-date-in-range-state-background-color) 50%,
    var(
        --mat-datepicker-calendar-date-in-comparison-range-state-background-color
      )
      50%
  );
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: var(
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color
  );
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: var(
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color
  );
}

.cdk-high-contrast-active .mat-datepicker-popup:not(:empty),
.cdk-high-contrast-active
  .mat-calendar-body-cell:not(.mat-calendar-body-in-range)
  .mat-calendar-body-selected {
  outline: solid 1px;
}

.cdk-high-contrast-active .mat-calendar-body-today {
  outline: dotted 1px;
}

.cdk-high-contrast-active .mat-calendar-body-cell::before,
.cdk-high-contrast-active .mat-calendar-body-cell::after,
.cdk-high-contrast-active .mat-calendar-body-selected {
  background: none;
}

.cdk-high-contrast-active .mat-calendar-body-in-range::before,
.cdk-high-contrast-active .mat-calendar-body-comparison-bridge-start::before,
.cdk-high-contrast-active .mat-calendar-body-comparison-bridge-end::before {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.cdk-high-contrast-active .mat-calendar-body-range-start::before {
  border-left: 1px solid;
}

[dir='rtl'] .cdk-high-contrast-active .mat-calendar-body-range-start::before {
  border-left: 0;
  border-right: 1px solid;
}

.cdk-high-contrast-active .mat-calendar-body-range-end::before {
  border-right: 1px solid;
}

[dir='rtl'] .cdk-high-contrast-active .mat-calendar-body-range-end::before {
  border-right: 0;
  border-left: 1px solid;
}

.cdk-high-contrast-active .mat-calendar-body-in-comparison-range::before {
  border-top: 1px dashed;
  border-bottom: 1px dashed;
}

.cdk-high-contrast-active .mat-calendar-body-comparison-start::before {
  border-left: 1px dashed;
}

[dir='rtl']
  .cdk-high-contrast-active
  .mat-calendar-body-comparison-start::before {
  border-left: 0;
  border-right: 1px dashed;
}

.cdk-high-contrast-active .mat-calendar-body-comparison-end::before {
  border-right: 1px dashed;
}

[dir='rtl']
  .cdk-high-contrast-active
  .mat-calendar-body-comparison-end::before {
  border-right: 0;
  border-left: 1px dashed;
}

[dir='rtl'] .mat-calendar-body-label {
  text-align: right;
}
