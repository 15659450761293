@use 'sass:map';
@use '@angular/material' as mat;

@mixin database-menu-color($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $accent: map.get($config, 'accent');

  .active {
    background-color: mat.m2-get-color-from-palette(
      $accent
    ) !important; // Must be important to override mat-menu rules
  }
}

@mixin database-menu-theme($theme) {
  // Extracts the color configurations from the theme.
  $color: mat.m2-get-color-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include database-menu-color($color);
  }
}
