@use 'sass:map';
@use '@angular/material' as mat;

@mixin thumbnail-renderer-colors($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);

  .pdf-preview-open {
    color: mat.m2-get-color-from-palette($accent, 'A200');
  }
}

@mixin thumbnail-renderer-colors-dark($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);

  .pdf-preview-open {
    color: mat.m2-get-color-from-palette($accent, 'A200');
  }
}

@mixin thumbnail-renderer-theme($theme, $dark-theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.m2-get-color-config($theme);
  $typography: mat.m2-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include thumbnail-renderer-colors($color);
    .dark-mode {
      @include thumbnail-renderer-colors-dark($dark-theme);
    }
  }
  @if $typography != null {
    @include thumbnail-renderer-typography($typography);
  }
}
