// Custom Themeing for AG-Grid
@use 'ag-grid-community/styles' as ag;
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import 'variables';

// Angular Material typography and core styles
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

/** Component theme mixins */

@import './app/components/navigation/navigation.component.theme.scss';
@import './app/components/database-menu/database-menu.component.theme.scss';
@import './app/components/grid-cell-components/badge-cell-renderer/badge-cell-renderer.component.theme.scss';
@import './app/components/custom-links-menu/custom-links.component.theme.scss';
@import './app/components/grid-cell-components/thumbnail-preview-renderer/thumbnail-preview-renderer.component.theme.scss';

/** Login module mixins */
@import './app/modules/login/components/login-button/login-button.component.theme.scss';

/** Themes */
$globalsearch-web-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $globalsearch-web-primary,
      accent: $globalsearch-web-accent,
      warn: $globalsearch-web-warn,
    ),
  )
);

$globalsearch-web-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $globalsearch-web-dark-primary,
      accent: $globalsearch-web-dark-accent,
      warn: $globalsearch-web-warn,
    ),
  )
);

/** Angular Material theme mixins */

@include mat.all-component-themes($globalsearch-web-theme);

.dark-mode {
  // Dark mode theme
  @include mat.all-component-colors($globalsearch-web-dark-theme);
}

/** Component theme mixins */

@include navigation-theme(
  $globalsearch-web-theme,
  $globalsearch-web-dark-theme
);

@include database-menu-theme($globalsearch-web-theme);

@include row-select-theme(
  $globalsearch-web-theme,
  $globalsearch-web-dark-theme
);

@include custom-links-theme(
  $globalsearch-web-theme,
  $globalsearch-web-dark-theme
);

@include thumbnail-renderer-theme(
  $globalsearch-web-theme,
  $globalsearch-web-dark-theme
);

/** Login module mixins */

@include login-button-theme(
  $globalsearch-web-theme,
  $globalsearch-web-dark-theme
);

/* Global Styles */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.background-accent {
  background-color: mat.m2-get-color-from-palette(
    $globalsearch-web-accent,
    500
  ) !important;
  color: mat.m2-get-color-from-palette(
    $globalsearch-web-accent,
    '500-contrast'
  ) !important;

  // Currently, darkmode accent is the same as light.
  // .dark-mode {
  //   background-color: mat.get-color-from-palette(
  //     $globalsearch-web-dark-accent,
  //     500
  //   );
  //   color: mat.get-color-from-palette(
  //     $globalsearch-web-dark-accent,
  //     '500-contrast'
  //   );
  // }
}

.dark-mode .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #08605f !important;
}

.dark-mode .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #08605f !important;
}

/* Scrollbar size */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Scrollbar Track */
*::-webkit-scrollbar-track {
  background: mat.m2-get-color-from-palette($globalsearch-web-primary, 50);
}

.dark-mode *::-webkit-scrollbar-track {
  background: mat.m2-get-color-from-palette(
    $globalsearch-web-dark-primary,
    400
  );
}

/* Notifications */
.toast-container .ngx-toastr {
  box-shadow: 0 0 6px #00000060 !important;
}

/* Scrollbar Handle */
*::-webkit-scrollbar-thumb {
  background: mat.m2-get-color-from-palette($globalsearch-web-accent, 200);
}

/* AG Grid style */

// Using mixin to allow for customization.
// See https://www.ag-grid.com/angular-data-grid/global-style-customisation-variables/

/* Default light mode theme */
@include ag.grid-styles(
  (
    theme: material,
    odd-row-background-color: mat.m2-get-color-from-palette(
        $globalsearch-web-primary,
        50
      ),
    material-primary-color: mat.m2-get-color-from-palette(
        $globalsearch-web-primary
      ),
    material-accent-color: mat.m2-get-color-from-palette(
        $globalsearch-web-accent
      ),
  )
);

.dark-mode {
  @include ag.grid-styles(
    (
      theme: material,
      foreground-color: map-get(mat.$m2-dark-theme-foreground-palette, text),
      background-color: map-get(
          mat.$m2-dark-theme-background-palette,
          background
        ),
      header-background-color: map-get(mat.$m2-grey-palette, 800),
      header-cell-hover-background-color: map-get(
          mat.$m2-dark-theme-background-palette,
          hover
        ),
      subheader-background-color: map-get(mat.$m2-grey-palette, 800),
      subheader-toolbar-background-color: map-get(mat.$m2-grey-palette, 800),
      odd-row-background-color: map-get(mat.$m2-grey-palette, 700),
      material-primary-color: mat.m2-get-color-from-palette(
          $globalsearch-web-dark-primary
        ),
      row-hover-color: map-get(mat.$m2-dark-theme-background-palette, hover),
      selected-row-background-color: map-get(
          mat.$m2-dark-theme-background-palette,
          selected-button
        ),
      checkbox-unchecked-color: map-get(
          mat.$m2-dark-theme-foreground-palette,
          icon
        ),
      checkbox-checked-color: mat.m2-get-color-from-palette(
          $globalsearch-web-accent
        ),
      border-color: map-get(mat.$m2-grey-palette, 700),
      control-panel-background-color: map-get(mat.$m2-grey-palette, 800),
      tooltip-background-color: map-get(
          mat.$m2-dark-theme-background-palette,
          background
        ),
    )
  );
}

.thumbnail-preview-cell {
  // Prevent cutoff of button ripple.
  box-sizing: content-box !important;
  // Do not draw a box around the selection/thumbnail cells.
  border: none !important;
}

// Icon button sizes style
@import '../node_modules/mat-icon-button-sizes/styles/mat-icon-button-sizes';

// This allows the use of the mat-table-fill-height class on a mat-tab-group
// see https://github.com/angular/components/issues/708#issuecomment-411532361
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group.mat-tab-fill-height .mat-mdc-tab-body-wrapper {
  flex: 1 1 100%;
}

// This allows the use of this class on AG-Grid column definitions.
.centered-grid-cell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: none !important;
}

.ag-cell-inline-editing {
  height: 94px !important;
}

.kfi-active.cdk-overlay-container,
.kfi-active .cdk-overlay-transparent-backdrop {
  pointer-events: none;
}

/*
  This imports a temporary fix for the ngx date picker. It should be removed along with the styles/_ngx-date-picker-fix.scss file once the issue is fixed.
  TODO: is this still required after moving libraries?
  Issue - https://github.com/h2qutc/angular-material-components/issues/357
*/
@import 'ngx-date-picker-fix';
