@use 'sass:map';
@use '@angular/material' as mat;

@mixin row-select-color($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, 'primary');

  .circle {
    background: mat.m2-get-color-from-palette($primary);
    color: white;
  }
}

@mixin row-select-color-dark($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, 'primary');

  .circle {
    background: mat.m2-get-color-from-palette($primary);
  }
}

@mixin row-select-typography($config-or-theme) {
  $config: mat.m2-get-typography-config($config-or-theme);

  .circle {
    font: {
      family: mat.m2-font-family($config, body-1);
      size: mat.m2-font-size($config, body-1);
      weight: mat.m2-font-weight($config, body-1);
    }
  }
}

@mixin row-select-theme($theme, $dark-theme) {
  $color: mat.m2-get-color-config($theme);
  $typography: mat.m2-get-typography-config($theme);

  @if $color != null {
    @include row-select-color($color);
    .dark-mode {
      @include row-select-color-dark($dark-theme);
    }
  }

  @if $typography != null {
    @include row-select-typography($typography);
  }
}
