@use 'sass:map';
@use '@angular/material' as mat;

@mixin login-button-color($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);

  .default-button-background-color {
    background-color: mat.m2-get-color-from-palette($primary) !important;
  }

  .default-button-color {
    color: #fff !important;
  }
}

@mixin login-button-color-dark($config-or-theme) {
  $config: mat.m2-get-color-config($config-or-theme);
  $primary: map.get($config, 'primary');
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');

  .default-button-background-color {
    background-color: mat.m2-get-color-from-palette($primary) !important;
  }

  .default-button-color {
    color: #fff !important;
  }
}

@mixin login-button-typography($config-or-theme) {
  $config: mat.m2-get-typography-config($config-or-theme);

  .button {
    // TODO I am not sure of these values but the fonts in the app as a whole are currently messed up
    // so it is hard to even check.
    font: {
      family: mat.m2-font-family($config, body-1);
      size: mat.m2-font-size($config, body-1);
      weight: mat.m2-font-weight($config, body-1);
    }
  }
}

@mixin login-button-theme($theme, $dark-theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.m2-get-color-config($theme);
  $typography: mat.m2-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include login-button-color($color);
    .dark-mode {
      @include login-button-color-dark($dark-theme);
    }
  }
  @if $typography != null {
    @include login-button-typography($typography);
  }
}
