@mixin MatIconButtonSize($name, $buttonSize, $iconSize) {
   .mat-#{$name}-icon-button {
      width: $buttonSize !important;
      height: $buttonSize !important;
      padding: 0px !important;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;

      & > *[role=img] {
         width: $iconSize;
         height: $iconSize;
         font-size: $iconSize;

         svg {
            width: $iconSize;
            height: $iconSize;
         }
      }

      .mat-mdc-button-touch-target {
         width: $buttonSize !important;
         height: $buttonSize !important;
      }
   }
}
